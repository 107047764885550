import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import TestimonialsLanding from "../components/testimonials_landing"

import BrandsLanding from "../components/brands_landing"
import BlogSection from "../components/blog_section"
import BlogSectionMobile from "../components/blog_section_mobile"

import flowlogo from "../images/logos/flowlogo.png"
import flowfuel from "../images/flowfuel.mp4"
import wave from "../images/wave.svg"

import flowfinding_testimonial_1 from "../images/flowfinding_testimonial_1.png"
import remax from "../images/logos/properties/remax.png"

import forAgencies from "../images/for_agencies.png"
import forPropertyDevelopers from "../images/for_property_developers.png"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Join the proptech revolution."
      description="Flow enables automated social media advertising for the real
      estate industry, providing high volumes of quality property
      leads and brand building."
      image={forAgencies}
    />

    <section className="w-full md:px-10 relative">
      <div className="container mx-auto py-5">
        <header className="flex flex-col-reverse md:flex-row w-full top-0">
          <div className="flex w-full md:w-1/2 mt-12 md:mt-0">
            <div className="self-center text-center md:text-left">
              <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight md:mx-0 animated fadeIn">
                Join the <span className="wrap">proptech</span> revolution.
              </h1>
              <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-md mx-auto mt-3 px-10 md:px-0">
                Join 100’s of Real Estate Agencies and Property Developers using
                Flow to automate and enable their social media marketing.
              </p>

              <Link
                to="/book-demo"
                className="inline-block bg-orange-500 text-white rounded-lg p-3 cursor-pointer mt-5"
              >
                Book a demo <i className="fas fa-arrow-right ml-2"></i>
              </Link>
            </div>
          </div>

          <div className="w-full md:w-1/2 mx-auto lottieWidth">
            <video width="500" height="500" playsInline autoPlay loop muted>
              <source src={flowfuel} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </header>
      </div>
    </section>

    <img className="w-full" src={wave} alt="" />

    <section className="w-full mt-10 md:mb-32 px-10 md:px-0">
      <h3 className="text-sm md:text-xl text-gray-700 text-center mt-4">
        Trusted by the top agencies and developers using Flow to grow their
        business.
      </h3>

      <div className="md:w-3/5 mx-auto py-10">
        < BrandsLanding />
      </div>
    </section>

    <section className="container mx-auto px-10 md:px-0 py-10">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl mx-auto font-bold text-gray-700 leading-none">
          Built for agencies, developers and agents
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
          Take advantage of the largest social platforms in the world to find
          people for your properties and ensure your brand is always
          Top-Of-Mind. It’s now possible with Flow automating and doing the
          heavy lifting.
        </p>
      </div>
    </section>

    <section className="mt-12 md:mt-0 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h3 className="text-lg text-center md:text-left font-medium text-orange-500 leading-none mt-4 md:mt-3">
            For Agencies
          </h3>
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Grow your agency business
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Flow automatically creates ads from your listings and brand profiles
            and targets them at the right people on Facebook and Instagram to
            build your brand and generate leads straight to your agents mobile.
          </p>

          <div className="w-full flex text-left max-w-sm mt-12">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Keep competition out - exclusively marketing your listings and
                brand.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Connect your property feed from Propdata and other leading
                partners.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Leads delivered to your agents with automated Whatsapp chat
                response to increase response time.
              </p>
            </div>
          </div>

          <Link
            to="/agencies"
            className="inline-block bg-orange-500 text-white rounded-lg p-3 cursor-pointer mt-10"
          >
            View Agency Solutions <i className="fas fa-arrow-right ml-2"></i>
          </Link>
        </div>
        <div className="w-full md:w-1/2">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pl-20"
            src={forAgencies}
            alt=""
          />
        </div>
      </div>
    </section>

    <section className="mt-12 md:mt-0 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col md:flex-row">
        <div className="w-full md:w-1/2">
          <img
            className="mx-auto w-full mt-10 md:mt-0 md:mb-10 md:mb-0 md:pr-20"
            src={forPropertyDevelopers}
            alt=""
          />
        </div>
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h3 className="text-lg text-center md:text-left font-medium text-orange-500 leading-none mt-4 md:mt-3">
            For Property Developers:
          </h3>
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Pre-sales quicker, developments sold out
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Flow automatically creates property ads for your developments on
            Facebook, Instagram and millions of websites, delivering leads to
            your agents to sell out your development.
          </p>

          <div className="w-full flex text-left max-w-sm mt-12">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Automated ads, 16+ formats that build hype, push presales, and
                sell out developments.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Quick integration to hundreds of CRMs, fits in with your
                operation.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Leads delivered to your agents with automated Whatsapp chat
                response to increase response time.
              </p>
            </div>
          </div>

          <Link
            to="/developers"
            className="inline-block bg-orange-500 text-white rounded-lg p-3 cursor-pointer mt-10"
          >
            View Developer Solutions <i className="fas fa-arrow-right ml-2"></i>
          </Link>
        </div>
      </div>
    </section>

    <img className="w-full" src={wave} alt="" />

    <section className="container mx-auto py-10 md:py-16">
      <div className="w-full overflow-hidden bg-gray-100 rounded-xl">
        <TestimonialsLanding />
      </div>
    </section>

    <section className="container mx-auto px-10 md:px-0 py-10 md:py-16">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl mx-auto font-bold text-gray-700 leading-none">
          Blog
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
          Grow your property business with Flow’s suite of products.
        </p>
      </div>

      <div className="hidden md:block"><BlogSection /></div>
      <BlogSectionMobile />
    </section>

    <section className="container mx-auto text-center px-5 md:px-0 mt-10 md:mt-0">
      <div className="bg-gray-100 w-full rounded-xl p-8 md:p-24">
        <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight md:mx-0 animated fadeIn">
          Join leading <span className="wrap">agencies</span>,{" "}
          <span className="wrap">developers</span> &{" "}
          <span className="wrap">agents</span> already using Flow to scale and automate their digital presence
        </h1>
        <Link
          to="/book-demo"
          className="inline-block bg-orange-500 text-white rounded-lg p-3 cursor-pointer mt-5"
        >
          Book a demo <i className="fas fa-arrow-right ml-2"></i>
        </Link>
      </div>
    </section>
    <Footer />
  </Layout>
)

export default IndexPage
