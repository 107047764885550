import React from "react"
import Flickity from "react-flickity-component"
import "../assets/css/flickity.css"
import { Link } from "gatsby"
import Moment from "react-moment"
import ReactMarkdown from "react-markdown"
import { StaticQuery, graphql } from "gatsby"

const flickityOptions = {
  prevNextButtons: false,
};

const BlogSectionMobile = () => (
  <StaticQuery
      query={graphql`
        query {
          allStrapiBlogArticle(sort: {fields: published_at, order: DESC}, limit: 3) {
            edges {
              node {
                strapiId
                published_at
                slug
                title
                content
                ogDescription
                category {
                  name
                }
                image {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Flickity className="w-full mt-12 md:hidden" options={flickityOptions}>
          {data.allStrapiBlogArticle.edges.map((article, i) => {
            return <Link to={`/blog/${article.node.slug}`} className="container mx-auto">
                <div className="">
                  <div className="">
                    <img
                      src={article.node.image.url}
                      alt={article.node.image.url}
                      height="100"
                      className="rounded-lg border border-gray-200 w-full h-48 object-cover"
                    />
                  </div>
                  <div className="">
                    <div className="flex justify-between text-sm text-gray-400 mt-5">
                      <Moment format="MMM Do YYYY">{article.node.published_at}</Moment>
                      <span className="bg-orange-500 text-xs text-white px-2 py-1 rounded-lg">
                        {article.node.category.name}
                      </span>
                    </div>
                    <p className="font-bold text-gray-800 text-xl leading-tight mt-2">
                      {article.node.title}
                    </p>
                    <p className="text-gray-600 text-sm leading-tight mt-2">
                      <ReactMarkdown source= {article.node.ogDescription} />
                    </p>
                    <Link
                      to={`/blog/${article.node.slug}`}
                      className="inline-block bg-orange-200 text-orange-500 rounded-lg p-3 cursor-pointer mt-5"
                    >
                      Read More <i className="fas fa-arrow-right ml-2"></i>
                    </Link>
                  </div>
                </div>
              </Link>
          })}
        </Flickity>
      )}
    />
)

export default BlogSectionMobile
