import React from "react"
import ReactDOM from "react-dom"
import Flickity from "react-flickity-component"

import "../assets/css/flickity.css"

import gerandkotze from "../images/testimonials/gerandkotze.png"
import nevillebrits from "../images/testimonials/nevillebrits.png"
import jessicahofmeyr from "../images/testimonials/jessicahofmeyr.png"
import barrydavies from "../images/testimonials/barrydavies.png"

import remax from "../images/logos/properties/remax.png"
import realnet from "../images/logos/properties/realnet.png"
import chaseveritt from "../images/logos/properties/chaseveritt.png"
import houss from "../images/logos/properties/houss.png"

const Testimonial = ({ testimonial, image, name, title, logo }) => {
  return (
    <section className="container mx-auto px-5 md:px-0 self-center">
      <div className="w-full p-8 md:p-16">
        <p className="font-medium tracking-tight text-gray-700 text-md md:text-xl">
          “{testimonial}”
        </p>
        <div className="flex mt-4 leading-none">
          <div className="rounded-full bg-orange-500 border-8 border-orange-200 h-24 w-24 p-2 mr-4 flex-shrink-0">
            <img className="w-16 h-16" src={image} alt="" />
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="mt-1 text-left self-center mr-4">
              <p className="">{name}</p>
              <p className="text-sm text-gray-400 mt-1">{title}</p>
            </div>
            <img className="self-center h-16 mr-auto" src={logo} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

function TestimonialsAgencies() {
  return (
    <Flickity className="w-full testimonials">
      <Testimonial
        testimonial="Marketing on Facebook is so complex and time consuming - but FlowFuel has really focused and consolidated our online reach and it's amazing to see such tangible, measurable results that is making the business more profitable and the agents' job easier."
        image={gerandkotze}
        name="Gerhard Kotze"
        title="MD RealNet Group"
        logo={realnet}
      />

      <Testimonial
        testimonial="Flow has enabled our franchise to access social media advertising on expert level through their technology automation. The best part is we hardly had to do a thing - they do the heavy lifting."
        image={nevillebrits}
        name="Neville Brits"
        title="RE/MAX Dazzle Broker Owner"
        logo={remax}
      />

      <Testimonial
        testimonial="We have worked with Flow on a number of successful branding, marketing and talent campaigns. As a customer service brand, our competitive differentiation is based on our ability to use digital technologies to win, serve and retain customers. Our business resides wherever our customers connect and wherever our agents interact with our customers needs. Flow has helped us increase our digital presence whilst delivering very real measurable and tangible results."
        image={barrydavies}
        name="Barry Davies"
        title="Director Chas Everitt"
        logo={chaseveritt}
      />

      <Testimonial
        testimonial="FlowFuel and FlowBrand have given us access to meaningful, measurable results across social media platforms by automating our listings. The ability to switch up our campaigns at a moment’s notice helps us optimise marketing spend and laser-focus our campaigns. It’s incredible optimisation and targeting has really focussed our media spend across our developments, finding the right people, in the right area, at the right price point, to target on the platforms where they spend hours of every day. It’s doing the job of an entire team and the fact that it’s ‘always-on’ means that it drives leads for us, every hour of every day"
        image={jessicahofmeyr}
        name="Jessica Hofmeyr"
        title="Head of Houss and Executive at Century Developments"
        logo={houss}
      />

    </Flickity>
  )
}

export default TestimonialsAgencies
